export var author:string = "Gowtham";
export var server: string = 'http://localhost:4200/';
export var var2: number = 2;
export var var3: string = 'var3';
/* export var baseurl:string = "http://excellenceadmin.developmentserver.work/pwaadmin";
export var baseurl:string = "https://test.excellence-games.com/excellencews";
 */
export var baseurl:string = "http://excellenceadmin.developmentserver.work/pwaadmin";


