import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { PwaadminService } from '../pwaadmin.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-update-faqsection',
  templateUrl: './update-faqsection.component.html',
  styleUrls: ['./update-faqsection.component.css']
})
export class UpdateFaqsectionComponent implements OnInit {

  public angForm: FormGroup;
  public permissionForm: FormGroup;
  submitted = false;
  message1 = '';
  status = '';
  userdata = [];
  userid:any;

  constructor(private fb: FormBuilder,private router:Router, private registerservice: PwaadminService,private route: ActivatedRoute) {}


  ngOnInit() {

    this.userid = this.route.snapshot.params.id;

    this.registerservice.getfaq(this.userid).pipe(first()).subscribe(
      data => {

      this.userdata = data;
      this.angForm.controls.question.setValue(data.question);
      this.angForm.controls.answer.setValue(data.answer);
      },

      error => {
      });

    this.angForm = this.fb.group({
      question: ['', Validators.required],
      answer: ['', Validators.required],
      });

  }

  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {

    this.submitted = true;

    this.registerservice.faqupdate( this.userid ,this.angForm.value.question,this.angForm.value.answer)
      .pipe(first())
      .subscribe(
      data => {

      this.submitted = true;

      this.router.navigate(['faqsection/list-faqsection'], {queryParams: { updatfaqsection: 'true'} });
  },

  error => {
    console.log(error);
    this.router.navigate(['faqsection/list-faqsection']);
  });
  }

}
