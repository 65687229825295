
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { PwaadminService } from './pwaadmin.service';

@Injectable({
providedIn: 'root'
})

export class AuthguardGuard implements CanActivate {
constructor(private dataService: PwaadminService,private router: Router ) {}
canActivate(
route: ActivatedRouteSnapshot,
state: RouterStateSnapshot): any {


  const token = localStorage.getItem('users');
  if(token) {

    return true;
  }else{
      return false;
   // return 'please login for the checkout';
  }

}



}

