import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { digitalListDB } from 'src/app/shared/tables/digital-list';
import { PwaadminService } from '../pwaadmin.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  public user_list = [];
  infoMessage:any;
  message1 = '';

  constructor( private registerservice: PwaadminService,private router:Router,private route: ActivatedRoute
    ) {
    //this.user_list = userListDB.list_user;

  }

  public settings = {
    mode: 'inline',
    pager : {
      perPage :10
    },
	  actions: {
      position: 'right',
      add: false,
      edit: false,
      delete: false,
    },
    columns: {
      fname: {
        title: 'First Name',
      },
      lname: {
        title: 'Last Name',
      },
      phone: {
        title: 'Phone',
      },
      email: {
        title: 'Email',
      },
      message: {
        title: 'Message',
      },


    },

  };

  ngOnInit() {

    this.registerservice.getcontactlist().pipe(first()).subscribe(
      data => {
        this.user_list = data;
      },

      error => {

      });
  }




}
