import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { PwaadminService } from '../pwaadmin.service';
import { Router,ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-faqsection',
  templateUrl: './faqsection.component.html',
  styleUrls: ['./faqsection.component.css']
})
export class FaqsectionComponent implements OnInit {

  public user_list = [];
  infoMessage:any;
  message1 = '';

  constructor( private registerservice: PwaadminService,private router:Router,private route: ActivatedRoute
    ) { }


  public settings = {
    mode: 'inline',
    delete: {confirmDelete: true},
    pager : {
      perPage :10
    },
	  actions: {
      position: 'right',
      add: false,
      edit: false,
      custom: [
        {
          name: 'editfaq',
          title: '<i class="fa fa-check"></i>',
        }

      ],
    },
    columns: {

      question: {
        title: 'Question',
        width: 800
      },
    },

  };

  ngOnInit() {

    this.registerservice.getfaqs().pipe(first()).subscribe(
      data => {
        this.user_list = data;
      },

      error => {

      });

      this.route.queryParams.subscribe(params => {
        if(params.addfaqsection !== undefined && params.addfaqsection === 'true') {
            this.infoMessage = 'Added successfully';
        }
      });

      this.route.queryParams.subscribe(params => {
        if(params.updatefaqsection !== undefined && params.updatefaqsection === 'true') {
            this.infoMessage = 'Updated successfully';
        }
      });
  }

  onCustom(event) {

    switch ( event.action) {
      case 'editfaq':
        this.router.navigate(['faqsection/update-faqsection/' + event.data.id]);
        break;

    }
  }

  onDeleteConfirm(event) {

    if (window.confirm('Are you sure you want to delete?')) {
      event.confirm.resolve();

      this.registerservice.faqdelete(event.data.id).pipe(first()).subscribe(
      data => {

      this.message1 = data.message;
      },

      error => {
      });

    } else {
      event.confirm.reject();
      console.log('reject');
    }
  }

}
