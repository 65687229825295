<div class="container-fluid">
  <div class="row">
      <div class="col-sm-12">
          <div class="card">
              <div class="card-header">
                  <h5>Contacts</h5>
              </div>
              <div class="card-body">

              <div *ngIf="infoMessage">
                <div class="alert alert-success alert-dismissible">
                  <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                  {{ infoMessage }}
                </div>
              </div>
              <div *ngIf="message1">
                   <div class="alert alert-success alert-dismissible">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                    {{ message1 }}
                  </div>
              </div>
                  <div id="batchDelete" class="custom-datatable">
                      <div class="table-responsive">
                        <ng2-smart-table [settings]="settings"  [source]="user_list" ></ng2-smart-table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
