import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { PwaadminService } from '../pwaadmin.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-question',
  templateUrl: './create-question.component.html',
  styleUrls: ['./create-question.component.css']
})
export class CreateQuestionComponent implements OnInit {

  public angForm: FormGroup;
  public permissionForm: FormGroup;
  public category_list = [];
  submitted = false;
  message1 = '';
  status = '';

  constructor(private fb: FormBuilder,private router:Router, private registerservice: PwaadminService) {}


  ngOnInit() {

    this.registerservice.getcategorylist().pipe(first()).subscribe(
      data => {
        this.category_list = data;
      },

      error => {

      });

    this.angForm = this.fb.group({
      question: ['', Validators.required],
      answerradio: ['', Validators.required],
      answername1: ['', Validators.required],
      answername2: ['', [Validators.required]],
      answername3: ['', [Validators.required]],
      categoryname: ['', [Validators.required]],
      });
  }

  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {

    this.submitted = true;


    this.registerservice.questionlistcreate(this.angForm.value.categoryname,this.angForm.value.question,this.angForm.value.answerradio,
      this.angForm.value.answername1,this.angForm.value.answername2,
      this.angForm.value.answername3)
      .pipe(first())
      .subscribe(
      data => {
        console.log(data);
        this.submitted = true;
        this.router.navigate(['question/list-question'], {queryParams: { addquestion: 'true'} });
      },

      error => {
      });
  }


}
