<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
      <div class="col-sm-12">
          <div class="card tab2-card">

              <div class="card-body tab2-card">

     <div class="tab-pane fade active show" id="account" role="tabpanel" aria-labelledby="account-tab">
      <div *ngIf="message1">
        <div class="alert alert-success alert-dismissible">
         <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
         {{ message1 }}
       </div>
       </div>

      <form [formGroup]="myForm" (ngSubmit)="submit()">

        <div class="form-group row">
          <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
               Contact </label>

              <input type="text" class="form-control col-xl-8 col-md-7" formControlName="contactno"  />

              <span class="col-xl-3 col-md-4"></span>
          <span class="text-danger col-xl-8 col-md-7"
              *ngIf="(f.contactno.touched || submitted) && f.contactno.errors?.required" >
              Contact is required
          </span>
      </div>

      <div class="form-group row">
        <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
          Adress </label>
             <textarea class="form-control col-xl-8 col-md-7" formControlName="address" cols="10" rows="03"></textarea>

            <span class="col-xl-3 col-md-4"></span>
      <span class="text-danger col-xl-8 col-md-7"
            *ngIf="(f.address.touched || submitted) && f.address.errors?.required" >
            Address is required
        </span>
    </div>

    <div class="form-group row">
      <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
           Email </label>

          <input type="email" class="form-control col-xl-8 col-md-7" formControlName="email"  />

          <span class="col-xl-3 col-md-4"></span>
      <span class="text-danger col-xl-8 col-md-7"
          *ngIf="(f.email.touched || submitted) && f.email.errors?.required" >
          Email is required
      </span>
  </div>




       <div class="pull-right">
         <button type="submit" [disabled]="myForm.invalid" class="btn btn-primary" >Update</button>
     </div>
   </form>
</div>

              </div>
          </div>
      </div>
  </div>
</div>
<!-- Container-fluid Ends-->
