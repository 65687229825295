<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
      <div class="col-sm-12">
          <div class="card tab2-card">

              <div class="card-body tab2-card">

     <div class="tab-pane fade active show" id="account" role="tabpanel" aria-labelledby="account-tab">
      <div  *ngIf=submitted>
        <div *ngIf="status;else other_content">
          <div class="alert alert-success"> {{ message1 }}</div>
        </div>

        <ng-template #other_content> <span class="alert alert-danger"> {{ message1 }}</span> </ng-template>


      </div>
      <form [formGroup]="angForm" (ngSubmit)="onsubmit()" autocomplete="off">

                                      <div class="form-group row">
                                          <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
                                              Question</label>

                                              <input type="text" class="form-control col-xl-8 col-md-7" formControlName="question">
                                              <span class="col-xl-3 col-md-4"></span>
                                        <span class="text-danger col-xl-8 col-md-7"
                                              *ngIf="(registerFormControl.question.touched || submitted) && registerFormControl.question.errors?.required" >
                                              Question is required
                                          </span>
                                      </div>
                                      <div class="form-group row">
                                          <label for="validationCustom1" class="col-xl-3 col-md-4"><span>*</span>Answer</label>
                                          <input class="form-control col-xl-8 col-md-7" id="validationCustom1"
                                              type="text"  formControlName="answer" required="">
                                              <span class="col-xl-3 col-md-4"></span>
                                              <span class="text-danger col-xl-8 col-md-7"
                                              *ngIf="(registerFormControl.answer.touched || submitted) && registerFormControl.answer.errors?.required" >
                                              Answer is required
                                          </span>
                                      </div>
                                      <div class="pull-right">

                                        <button type="submit" [disabled]="angForm.invalid" class="btn btn-primary" >Create</button>
                                    </div>
                                  </form>
                              </div>



              </div>
          </div>
      </div>
  </div>
</div>
<!-- Container-fluid Ends-->
