import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { PwaadminService } from '../pwaadmin.service';

@Component({
  selector: 'app-images-product',
  templateUrl: './images-product.component.html',
  styleUrls: ['./images-product.component.css']
})
export class ImagesProductComponent implements OnInit {

  constructor(private router:Router, private http: HttpClient,private route: ActivatedRoute, private registerservice: PwaadminService) { }

  imageDeleteFrom: FormGroup;
  imageurls =[];
  base64String: string;
  name: string;
  imagePath: string;
  message1 = '';
  image_list= '';
  image:any;
  productid:any;
  ngOnInit() {

    let id = this.route.snapshot.params.id;
    this.registerservice.getproductimage(id).pipe(first()).subscribe(
      data => {

        this.image_list = data;
      },

      error => {

      });

  }

  removeImageEdit(i: any, imagepath: any) {
    console.log('removeedit');
    this.imageDeleteFrom.value.id = i;
    this.imageDeleteFrom.value.ImagePath = imagepath;
  }

  removeImage(i: number) {
    console.log('removeImage');
    this.imageurls.splice(i, 1);
  }

  removeImageproduct(id: number) {

    console.log(id);
    this.registerservice.productimagedelete(id)
    .pipe(first())
    .subscribe(
    data => {
      window.location.reload();
    },
  )


  this.message1 = 'Removed successfully';
  }

  onSelectFile(event:any) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;

      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.imageurls.push({ base64String: event.target.result});

          let id = this.route.snapshot.params.id;
         console.log(event.target.result);
         console.log(id);
          this.registerservice.updateproductimage(event.target.result,id)
          .pipe(first())
          .subscribe(
          data => {
          console.log(data);
            this.message1 = 'Uploaded successfully';
          },
        )
        }


        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

}
