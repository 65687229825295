import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { PwaadminService } from '../pwaadmin.service';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-update-product',
  templateUrl: './update-product.component.html',
  styleUrls: ['./update-product.component.css']
})
export class UpdateProductComponent implements OnInit {

  public angForm: FormGroup;
  public permissionForm: FormGroup;
  submitted = false;
  message1 = '';
  status = '';
  userdata = [];
  productid:any;

  constructor(private fb: FormBuilder,private router:Router, private registerservice: PwaadminService,private route: ActivatedRoute) {}


  ngOnInit() {

    this.productid = this.route.snapshot.params.id;

    this.registerservice.getproduct(this.productid).pipe(first()).subscribe(
      data => {
      this.userdata = data;
      this.angForm.controls.productname.setValue(data.productname);
      this.angForm.controls.productprice.setValue(data.productprice);
      this.angForm.controls.productdetail.setValue(data.productdetail);
      this.angForm.controls.productdescription.setValue(data.productdescription);
      },

      error => {
      });

    this.angForm = this.fb.group({
      productname: ['', Validators.required],
      productprice: ['', Validators.required],
      productdetail: ['', Validators.required],
      productdescription: ['', [Validators.required]],
      });

  }

  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {

    this.submitted = true;

    this.registerservice.productlistupdate( this.productid ,this.angForm.value.productname,this.angForm.value.productprice,this.angForm.value.productdetail,this.angForm.value.productdescription)
      .pipe(first())
      .subscribe(
      data => {

      this.submitted = true;

      this.router.navigate(['product/list-product'], {queryParams: { updateproduct: 'true'} });
  },

  error => {
    console.log(error);
    this.router.navigate(['product/list-product']);
  });
  }

}
