import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AdminUserComponent } from './admin-user/admin-user.component';
import { HeaderComponent } from './header/header.component';
import { NavService } from './nav.service';
import { WINDOW_PROVIDERS } from './windows.service';
import { FeatherIconsComponent } from './feather-icons/feather-icons.component';
import { ToggleFullscreenDirective } from "./fullscreen.directive";
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CreateAdminUserComponent } from './create-admin-user/create-admin-user.component';
import { HttpClientModule } from '@angular/common/http';
import { UpdateAdminUserComponent } from './update-admin-user/update-admin-user.component';
import { DeleteAdminUserComponent } from './delete-admin-user/delete-admin-user.component';
import { PagesComponent } from './pages/pages.component';
import { ProductComponent } from './product/product.component';
import { CreateProductComponent } from './create-product/create-product.component';
import { UpdateProductComponent } from './update-product/update-product.component';
import { DeleteProductComponent } from './delete-product/delete-product.component';
import { CategoryComponent } from './category/category.component';
import { CreateCategoryComponent } from './create-category/create-category.component';
import { UpdateCategoryComponent } from './update-category/update-category.component';
import { DeleteCategoryComponent } from './delete-category/delete-category.component';
import { QuestionComponent } from './question/question.component';
import { CreateQuestionComponent } from './create-question/create-question.component';
import { UpdateQuestionComponent } from './update-question/update-question.component';
import { DeleteQuestionComponent } from './delete-question/delete-question.component';
import { UserComponent } from './user/user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { FaqsectionComponent } from './faqsection/faqsection.component';
import { CreateFaqsectionComponent } from './create-faqsection/create-faqsection.component';
import { UpdateFaqsectionComponent } from './update-faqsection/update-faqsection.component';
import { UpdatePageComponent } from './update-page/update-page.component';
import { ContactusComponent } from './contactus/contactus.component';
import { UpdateSettingComponent } from './update-setting/update-setting.component';
import { ImagesProductComponent } from './images-product/images-product.component';
import { ActivityComponent } from './activity/activity.component';
import { CreateActivityComponent } from './create-activity/create-activity.component';
import { UpdateActivityComponent } from './update-activity/update-activity.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminUserComponent,
    HeaderComponent,
    FeatherIconsComponent,
    ToggleFullscreenDirective,
    SidebarComponent,
    FooterComponent,
    BreadcrumbComponent,
    RightSidebarComponent,
    CreateAdminUserComponent,
    UpdateAdminUserComponent,
    DeleteAdminUserComponent,
    PagesComponent,
    ProductComponent,
    CreateProductComponent,
    UpdateProductComponent,
    DeleteProductComponent,
    CategoryComponent,
    CreateCategoryComponent,
    UpdateCategoryComponent,
    DeleteCategoryComponent,
    QuestionComponent,
    CreateQuestionComponent,
    UpdateQuestionComponent,
    DeleteQuestionComponent,
    UserComponent,
    CreateUserComponent,
    UpdateUserComponent,
    FaqsectionComponent,
    CreateFaqsectionComponent,
    UpdateFaqsectionComponent,
    UpdatePageComponent,
    ContactusComponent,
    UpdateSettingComponent,
    ImagesProductComponent,
    ActivityComponent,
    CreateActivityComponent,
    UpdateActivityComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SmartTableModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [NavService, WINDOW_PROVIDERS],
  bootstrap: [AppComponent]
})
export class AppModule { }
