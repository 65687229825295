import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { PwaadminService } from '../pwaadmin.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {

  public angForm: FormGroup;
  public permissionForm: FormGroup;
  submitted = false;
  message1 = '';
  status = '';

  constructor(private fb: FormBuilder,private router:Router, private registerservice: PwaadminService) {}

  ngOnInit() {
    this.angForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      code: ['', Validators.required],
      });
  }

  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {

    this.submitted = true;

    this.registerservice.usercreate(this.angForm.value.fname,this.angForm.value.lname,this.angForm.value.email,this.angForm.value.password,this.angForm.value.code)
      .pipe(first())
      .subscribe(
      data => {
        this.router.navigate(['users/list-user'], {queryParams: { adduser: 'true'} });
      },
      error => {
      });
  }

}
