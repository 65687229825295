import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { PwaadminService } from '../pwaadmin.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-faqsection',
  templateUrl: './create-faqsection.component.html',
  styleUrls: ['./create-faqsection.component.css']
})
export class CreateFaqsectionComponent implements OnInit {


  public angForm: FormGroup;
  public permissionForm: FormGroup;
  submitted = false;
  message1 = '';
  status = '';

  constructor(private fb: FormBuilder,private router:Router, private registerservice: PwaadminService) {}

  ngOnInit() {
    this.angForm = this.fb.group({
      question: ['', Validators.required],
      answer: ['', Validators.required],
      });
  }

  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {

    this.submitted = true;

    this.registerservice.faqcreate(this.angForm.value.question,this.angForm.value.answer)
      .pipe(first())
      .subscribe(
      data => {
        this.router.navigate(['faqsection/list-faqsection'], {queryParams: { addfaqsection: 'true'} });
      },
      error => {
      });
  }

}
