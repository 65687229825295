import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { PwaadminService } from '../pwaadmin.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-activity',
  templateUrl: './create-activity.component.html',
  styleUrls: ['./create-activity.component.css']
})
export class CreateActivityComponent implements OnInit {

  public angForm: FormGroup;
  public permissionForm: FormGroup;
  submitted = false;
  message1 = '';
  status = '';

  constructor(private fb: FormBuilder,private router:Router, private registerservice: PwaadminService) {}


  ngOnInit() {
    this.angForm = this.fb.group({
      name: ['', Validators.required],
      });
  }

  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {

    this.submitted = true;
    this.registerservice.activitycreate(this.angForm.value.name)
      .pipe(first())
      .subscribe(
      data => {
        // this.submitted = true;
        console.log(data);
        this.router.navigate(['activity/list-activity'], {queryParams: { addactivity: 'true'} });
      },

      error => {
      });
  }

}
