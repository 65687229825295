<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="card">
      <div class="card-header">
          <h5>User Details</h5>
      </div>

      <div class="card-body">
          <div class="btn-popup pull-right">
              <a class="btn btn-secondary" [routerLink]="'/users/create-user'">Create User</a>
          </div>
          <div *ngIf="infoMessage">
            <div class="alert alert-success alert-dismissible">
              <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
              {{ infoMessage }}
            </div>
          </div>
          <div *ngIf="message1">
            <div class="alert alert-success alert-dismissible">
             <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
             {{ message1 }}
           </div>
       </div>
          <div id="batchDelete" class="category-table custom-datatable transcation-datatable">
              <div class="table-responsive">
                <ng2-smart-table [settings]="settings" (deleteConfirm)="onDeleteConfirm($event)" [source]="user_list" (custom)="onCustom($event)"></ng2-smart-table>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- Container-fluid Ends-->
