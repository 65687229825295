import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { PwaadminService } from '../pwaadmin.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-admin-user',
  templateUrl: './create-admin-user.component.html',
  styleUrls: ['./create-admin-user.component.css']
})
export class CreateAdminUserComponent implements OnInit {

  public angForm: FormGroup;
  public permissionForm: FormGroup;
  submitted = false;
  message1 = '';
  status = '';

  constructor(private fb: FormBuilder,private router:Router, private registerservice: PwaadminService) {}


  ngOnInit() {
    this.angForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      });
  }

  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {

    this.submitted = true;

    this.registerservice.adminusercreate(this.angForm.value.fname,this.angForm.value.lname,this.angForm.value.email,this.angForm.value.password)
      .pipe(first())
      .subscribe(
      data => {
        this.submitted = true;

        this.router.navigate(['admin-user/list-user'], {queryParams: { adduser: 'true'} });


      },

      error => {
      });
  }


}
