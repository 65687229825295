<div class="container-fluid">
  <div class="row">
      <div class="col-sm-12">
          <div class="card">
              <div class="card-header">
                  <h5>Product Lists</h5>
              </div>
              <div class="card-body">
                <div class="btn-popup pull-right">
                  <a class="btn btn-secondary" [routerLink]="'/product/create-product'">Create Product</a>
              </div>
              <div *ngIf="infoMessage">
                <div class="alert alert-success alert-dismissible">
                  <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                  {{ infoMessage }}
                </div>
              </div>
              <div *ngIf="message1">
                   <div class="alert alert-success alert-dismissible">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                    {{ message1 }}
                  </div>
              </div>
                  <div id="batchDelete" class="custom-datatable">
                      <div class="table-responsive">
                        <ng2-smart-table [settings]="settings" (deleteConfirm)="onDeleteConfirm($event)" [source]="user_list" (custom)="onCustom($event)"></ng2-smart-table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
