
  <div class="authentication-box">
      <div class="container">
          <div class="row">

              <div class="col-md-12 text-center">
                  <div class="card ">
                      <div class="card-body">
          <div class="form-group">
            <img class="blur-up lazyloaded" src="assets/images/dashboard/login_logo.png" alt="">
          </div>
          <div  *ngIf=submitted>
            <div *ngIf="status;else other_content">
              <div class="alert alert-success"> {{ message1 }}</div>
            </div>

            <ng-template #other_content> <div class="alert alert-danger"> {{ message1 }}</div> </ng-template>


          </div>


<form [formGroup]="loginForm" (ngSubmit)="onsubmit()" autocomplete="off" class="form-horizontal auth-form" >
                                <div class="form-group">
                                  <input type="email" name="email" formControlName="email" autocomplete="off" class="form-control" placeholder="Email">

                                  <span class="text-danger"
                                  *ngIf="(loginFormControl.email.touched || submitted) && loginFormControl.email.errors?.email">
                                  Please enter valid email
                                  </span>
                                  <span class="text-danger"
                                  *ngIf="(loginFormControl.email.touched || submitted) && loginFormControl.email.errors?.required">
                                  Email is required
                                  </span>
                                </div>
                                              <div class="form-group">
                                                <input type="password" name="password" formControlName="password" autocomplete="off" class="form-control input-sm" placeholder="Password">

                                                <span class="text-danger"
                                               *ngIf="(loginFormControl.password.touched || submitted) && loginFormControl.password.errors?.required">
Password is required
</span>
                                              </div>

                                              <div class="form-button">

                                                  <button type="submit" class="btn btn-primary" [disabled]="loginForm.invalid">Login</button>
                                              </div>

                                          </form>
                      </div>
                  </div>
              </div>
          </div>

      </div>
  </div>

