import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { PwaadminService } from '../pwaadmin.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-update-page',
  templateUrl: './update-page.component.html',
  styleUrls: ['./update-page.component.css']
})
export class UpdatePageComponent implements OnInit {

  public angForm: FormGroup;
  public permissionForm: FormGroup;
  submitted = false;
  message1 = '';
  status = '';
  userdata = [];
  userid:any;

  constructor(private fb: FormBuilder,private router:Router, private registerservice: PwaadminService,private route: ActivatedRoute) {}


  ngOnInit() {

    this.userid = this.route.snapshot.params.id;

    this.registerservice.getpage(this.userid).pipe(first()).subscribe(
      data => {

      this.userdata = data;
      this.angForm.controls.description.setValue(data.description);
      },

      error => {
      });

    this.angForm = this.fb.group({
      description: ['', Validators.required],
      });

  }

  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {

    this.submitted = true;

    this.registerservice.pageupdate( this.userid ,this.angForm.value.description)
      .pipe(first())
      .subscribe(
      data => {

      this.submitted = true;
      this.router.navigate(['pages/list-page'], {queryParams: { updateuser: 'true'} });
  },

  error => {
    console.log(error);
    this.router.navigate(['pages/list-page']);
  });
  }


}
