<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
      <div class="col-sm-12">
          <div class="card tab2-card">

              <div class="card-body tab2-card">

     <div class="tab-pane fade active show" id="account" role="tabpanel" aria-labelledby="account-tab">
      <div  *ngIf=submitted>
        <div *ngIf="status;else other_content">
          <div class="alert alert-success"> {{ message1 }}</div>
        </div>

        <ng-template #other_content> <span class="alert alert-danger"> {{ message1 }}</span> </ng-template>


      </div>
      <form [formGroup]="angForm" (ngSubmit)="onsubmit()" autocomplete="off">

                                      <div class="form-group row">
                                          <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
                                              Category Name</label>

                                              <input type="text" class="form-control col-xl-8 col-md-7" formControlName="name">
                                              <span class="col-xl-3 col-md-4"></span>
                                        <span class="text-danger col-xl-8 col-md-7"
                                              *ngIf="(registerFormControl.name.touched || submitted) && registerFormControl.name.errors?.required" >
                                              Category Name is required
                                          </span>
                                      </div>

                                      <div class="form-group row">
                                        <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
                                            Color</label>

                                            <input type="text" class="form-control col-xl-8 col-md-7" formControlName="color">
                                            <span class="col-xl-3 col-md-4"></span>
                                      <span class="text-danger col-xl-8 col-md-7"
                                            *ngIf="(registerFormControl.color.touched || submitted) && registerFormControl.color.errors?.required" >
                                            Color is required
                                        </span>
                                    </div>
                                      <div class="pull-right">
                                        <button type="submit" [disabled]="angForm.invalid" class="btn btn-primary" >Update</button>
                                    </div>
                                  </form>
                              </div>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- Container-fluid Ends-->
