import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import * as glob from './globals';

@Injectable({
  providedIn: 'root'
})
export class PwaadminService {

  baseUrl:string =  glob.baseurl;
  Users:  any;
  constructor(private httpClient : HttpClient) { }

  public adminusercreate(name: any,lname: any,email: any,pwd: any) {

      return this.httpClient.post<any>(this.baseUrl + '/add-adminuser.php', { name,lname,email, pwd })
      .pipe(map((Users: any) => {
      return Users;
      }));
    }

    public adminuserupdate(userid:any, fname: any,lname: any,email: any,pwd: any) {

      return this.httpClient.post<any>(this.baseUrl + '/updateadminuser.php', {userid,fname,lname,email, pwd })
      .pipe(map((Users: any) => {
      return Users;
      }));
    }

    public getadminusers() {
          return this.httpClient.post<any>(this.baseUrl + '/adminusers.php', { })
            .pipe(map((Users: any) => {
              return Users;
          }));
    }

    public getadminuser(id: any) {
      return this.httpClient.post<any>(this.baseUrl + '/singleadminuser.php', {id })
        .pipe(map((Users: any) => {
          return Users;
      }));
    }
    public adminuserdelete(id: any) {
      return this.httpClient.post<any>(this.baseUrl + '/deleteadminuser.php', {id })
        .pipe(map((Users: any) => {
          return Users;
      }));
    }

    public getusers() {
      return this.httpClient.post<any>(this.baseUrl + '/users.php', { })
        .pipe(map((Users: any) => {
          return Users;
      }));

    }

      public getuser(id: any) {
        return this.httpClient.post<any>(this.baseUrl + '/singleuser.php', {id })
          .pipe(map((Users: any) => {
            return Users;
        }));
      }

      public usercreate(name: any,lname: any,email: any,pwd: any,code: any) {

        return this.httpClient.post<any>(this.baseUrl + '/add-user.php', { name,lname,email, pwd , code})
        .pipe(map((Users: any) => {
          console.log(Users);
        return Users;
        }));
      }

      public userupdate(userid:any, fname: any,lname: any,email: any,pwd: any,code: any) {

        return this.httpClient.post<any>(this.baseUrl + '/updateauser.php', {userid, fname, lname, email, pwd, code })
        .pipe(map((Users: any) => {
        return Users;
        }));
      }

      public userdelete(id: any) {
        return this.httpClient.post<any>(this.baseUrl + '/deleteuser.php', {id })
          .pipe(map((Users: any) => {
            return Users;
        }));
      }


    public userlogin(email: any,password: any) {
          return this.httpClient.post<any>(this.baseUrl + '/login.php', { email, password })
            .pipe(map((Users: any) => {
              return Users;
          }));
    }

    public getcategorylist() {
      return this.httpClient.post<any>(this.baseUrl + '/categorylist.php', { })
        .pipe(map((Users: any) => {
          return Users;
      }));
    }

    public getcategory(id: any) {
      return this.httpClient.post<any>(this.baseUrl + '/singlecategory.php', {id })
        .pipe(map((Users: any) => {
          return Users;
      }));
    }

    public categorylistcreate(name: any) {

      return this.httpClient.post<any>(this.baseUrl + '/add-category.php', { name })
      .pipe(map((Users: any) => {
      return Users;
      }));
    }

    public categorylistupdate(id:any,name: any,color:any) {

      return this.httpClient.post<any>(this.baseUrl + '/updatecategory.php', {id, name, color })
      .pipe(map((Users: any) => {
      return Users;
      }));
    }

    public categorydelete(id: any) {
      return this.httpClient.post<any>(this.baseUrl + '/deletecategory.php', {id })
        .pipe(map((Users: any) => {
          return Users;
      }));
    }


    public getproductlist() {
      return this.httpClient.post<any>(this.baseUrl + '/productlist.php', { })
        .pipe(map((Users: any) => {
          return Users;
      }));
    }

    public getproduct(id: any) {
      return this.httpClient.post<any>(this.baseUrl + '/singleproduct.php', {id })
        .pipe(map((Users: any) => {
          return Users;
      }));
    }

    public productlistcreate(productname: any,productprice: any,productdetail: any,productdescription: any) {

      return this.httpClient.post<any>(this.baseUrl + '/add-product.php', { productname,productprice,productdetail, productdescription })
      .pipe(map((Users: any) => {
      return Users;
      }));
    }

    public productlistupdate(productid:any,productname: any,productprice: any,productdetail: any,productdescription: any) {

      return this.httpClient.post<any>(this.baseUrl + '/updateproduct.php', {productid, productname,productprice,productdetail, productdescription })
      .pipe(map((Users: any) => {
      return Users;
      }));
    }

    public productdelete(productid: any) {
      return this.httpClient.post<any>(this.baseUrl + '/deleteproduct.php', {productid })
        .pipe(map((Users: any) => {
          return Users;
      }));
    }

    public getquestionlist() {
      return this.httpClient.post<any>(this.baseUrl + '/questionlist.php', { })
        .pipe(map((Users: any) => {
          return Users;
      }));
    }

    public questiondelete(questionid: any) {
      return this.httpClient.post<any>(this.baseUrl + '/deletequestion.php', { questionid })
        .pipe(map((Users: any) => {
          return Users;
      }));
    }

    public questionlistcreate(categoryname: any,question: any,answerradio: any,answername1: any,answername2: any,answername3: any) {

      return this.httpClient.post<any>(this.baseUrl + '/add-question.php', { categoryname,question,answerradio, answername1 , answername2 , answername3 })
      .pipe(map((Users: any) => {
      return Users;
      }));
    }
    public questionlistupdate(questionid:any,categoryname: any,question: any,answerradio: any,answername1: any,answername2: any,answername3: any) {

      return this.httpClient.post<any>(this.baseUrl + '/updatequestion.php', {questionid, categoryname,question,answerradio, answername1 , answername2 , answername3 })
      .pipe(map((Users: any) => {
      return Users;
      }));
    }
    public getquestionsingle(questionid: any) {
      return this.httpClient.post<any>(this.baseUrl + '/singlequestion.php', { questionid })
        .pipe(map((Users: any) => {
          return Users;
      }));
    }

    public getfaqs() {
      return this.httpClient.post<any>(this.baseUrl + '/faqsection.php', { })
        .pipe(map((Users: any) => {
          return Users;
      }));

    }

      public getfaq(id: any) {
        return this.httpClient.post<any>(this.baseUrl + '/singlefaqsection.php', {id })
          .pipe(map((Users: any) => {
            return Users;
        }));
      }

      public faqcreate(question: any,answer: any) {

        return this.httpClient.post<any>(this.baseUrl + '/add-faqsection.php', { question,answer})
        .pipe(map((Users: any) => {
          console.log(Users);
        return Users;
        }));
      }

      public faqupdate(id:any, question: any,answer: any) {

        return this.httpClient.post<any>(this.baseUrl + '/updatefaqsection.php', {id, question, answer})
        .pipe(map((Users: any) => {
        return Users;
        }));
      }

      public faqdelete(id: any) {
        return this.httpClient.post<any>(this.baseUrl + '/deletefaqsection.php', {id })
          .pipe(map((Users: any) => {
            return Users;
        }));
      }

      public getpages() {
        return this.httpClient.post<any>(this.baseUrl + '/pages.php', { })
          .pipe(map((Users: any) => {
            return Users;
        }));

      }

        public getpage(id: any) {
          return this.httpClient.post<any>(this.baseUrl + '/singlepage.php', {id })
            .pipe(map((Users: any) => {
              return Users;
          }));
        }
        public pageupdate(id:any, description: any) {

          return this.httpClient.post<any>(this.baseUrl + '/updatepage.php', {id, description})
          .pipe(map((Users: any) => {
          return Users;
          }));
        }
        public getcontactlist() {
          return this.httpClient.post<any>(this.baseUrl + '/contacts.php', { })
            .pipe(map((Users: any) => {
              return Users;
          }));

        }

        public singlesetting() {
          return this.httpClient.post<any>(this.baseUrl + '/singlesetting.php', { })
            .pipe(map((Users: any) => {
              return Users;
          }));

        }
        public updateproductimage(name:any, productid: any) {

          return this.httpClient.post<any>(this.baseUrl + '/add-productimage.php', {name, productid})
          .pipe(map((Users: any) => {
          return Users;
          }));
        }

        public getproductimage(productid: any) {

          return this.httpClient.post<any>(this.baseUrl + '/singleproductimage.php', {productid})
          .pipe(map((Users: any) => {
          return Users;
          }));
        }

        public productimagedelete(productid: any) {
          return this.httpClient.post<any>(this.baseUrl + '/deleteimage.php', { productid })
            .pipe(map((Users: any) => {
              return Users;
          }));
        }

        public getactivitylist() {
          return this.httpClient.post<any>(this.baseUrl + '/activity.php', { })
            .pipe(map((Users: any) => {
              return Users;
          }));
        }
        public getactivity(id) {
          return this.httpClient.post<any>(this.baseUrl + '/singleactivity.php', { id })
            .pipe(map((Users: any) => {
              return Users;
          }));
        }
        public activitycreate(name: any) {

          return this.httpClient.post<any>(this.baseUrl + '/add-activity.php', { name })
          .pipe(map((Users: any) => {
          return Users;
          }));
        }

        public activityupdate(id:any,name:any) {

          return this.httpClient.post<any>(this.baseUrl + '/updateactivity.php', {id, name})
          .pipe(map((Users: any) => {
          return Users;
          }));
        }

        public activitydelete(id: any) {
          return this.httpClient.post<any>(this.baseUrl + '/deleteactivity.php', {id })
            .pipe(map((Users: any) => {
              return Users;
          }));
        }



}
