import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { PwaadminService } from '../pwaadmin.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-update-admin-user',
  templateUrl: './update-admin-user.component.html',
  styleUrls: ['./update-admin-user.component.css']
})
export class UpdateAdminUserComponent implements OnInit {

  public angForm: FormGroup;
  public permissionForm: FormGroup;
  submitted = false;
  message1 = '';
  status = '';
  userdata = [];
  userid:any;

  constructor(private fb: FormBuilder,private router:Router, private registerservice: PwaadminService,private route: ActivatedRoute) {}


  ngOnInit() {

    this.userid = this.route.snapshot.params.id;

    this.registerservice.getadminuser(this.userid).pipe(first()).subscribe(
      data => {

      this.userdata = data;
      this.angForm.controls.fname.setValue(data.fname);
      this.angForm.controls.lname.setValue(data.lname);
      this.angForm.controls.email.setValue(data.email);

      },

      error => {
      });

    this.angForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: [''],
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      });

  }

  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {

    this.submitted = true;

    this.registerservice.adminuserupdate( this.userid ,this.angForm.value.fname,this.angForm.value.lname,this.angForm.value.email,this.angForm.value.password)
      .pipe(first())
      .subscribe(
      data => {

      this.submitted = true;

      this.router.navigate(['admin-user/list-user'], {queryParams: { updateuser: 'true'} });
  },

  error => {
    console.log(error);
    this.router.navigate(['admin-user/list-user']);
  });
  }


}
