import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { digitalListDB } from 'src/app/shared/tables/digital-list';
import { PwaadminService } from '../pwaadmin.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  public user_list = [];
  infoMessage:any;
  message1 = '';

  constructor( private registerservice: PwaadminService,private router:Router,private route: ActivatedRoute
    ) {
    //this.user_list = userListDB.list_user;

  }

  public settings = {
    mode: 'inline',
    delete: {confirmDelete: true},
    pager : {
      perPage :10
    },
	  actions: {
      position: 'right',
      add: false,
      edit: false,
      delete:false,
      custom: [
        {
          name: 'editcategory',
          title: '<i class="fa fa-check"></i>',
        }

      ],
    },
    columns: {
     name: {
        title: 'Category Name',
      },


    },

  };

  ngOnInit() {

    this.registerservice.getcategorylist().pipe(first()).subscribe(
      data => {
        this.user_list = data;
      },

      error => {

      });

      this.route.queryParams.subscribe(params => {
        if(params.addcategory !== undefined && params.addcategory === 'true') {
            this.infoMessage = 'Added successfully';
        }
      });

      this.route.queryParams.subscribe(params => {
        if(params.updatecategory !== undefined && params.updatecategory === 'true') {
            this.infoMessage = 'Updated successfully';
        }
      });
  }

  onCustom(event) {

    switch ( event.action) {
      case 'editcategory':
        this.router.navigate(['category/update-category/' + event.data.categoryid]);
        break;

    }
  }

  onDeleteConfirm(event) {

    if (window.confirm('Are you sure you want to delete?')) {
      event.confirm.resolve();

      this.registerservice.categorydelete(event.data.categoryid).pipe(first()).subscribe(
      data => {
      this.message1 = data.message;
      },

      error => {
      });

    } else {
      event.confirm.reject();
    }
  }


}
