<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div *ngIf="message1">
        <div class="alert alert-success alert-dismissible">
         <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
         {{ message1 }}
       </div>
   </div>
      <div class="row">

        <div class="col-md-4 productAddfromImages" *ngFor='let url1 of image_list;'>
        <!-- <img class="img-fluid" [src]="url1.image">
          <a (click)="removeImageproduct(url1.productid)" class="btn btn-xs btn-danger">Remove</a> -->
        </div>

        <div class="col-md-2 productAddfromImages" *ngFor='let url of imageurls; let i = index'>
          <img class="img-fluid" [src]="url.base64String">
          <a (click)="removeImage(i)" class="btn btn-xs btn-danger">Remove</a>
        </div>
      </div>
    </div>
    <div class="card main-title">
      <div class="col-md-12">
        <div class="form-group">
          <br>
          <label class="form-label">Add Images</label>
          <input type="file"
                 class="form-control"
                 (change)="onSelectFile($event)"
                 multiple accept="image/*" />
        </div>
      </div>
    </div>
  </div>
</div>
