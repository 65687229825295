import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { digitalListDB } from 'src/app/shared/tables/digital-list';
import { PwaadminService } from '../pwaadmin.service';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {
  public user_list = [];
  infoMessage:any;
  message1 = '';

  constructor( private registerservice: PwaadminService,private router:Router,private route: ActivatedRoute
    ) {
    //this.user_list = userListDB.list_user;

  }

  public settings = {
    mode: 'inline',

    pager : {
      perPage :10
    },
	  actions: {
      position: 'right',
      add: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: 'editpage',
          title: '<i class="fa fa-check"></i>',
        }

      ],
    },
    columns: {
      name: {
        title: 'Page Name',
      },
    },

  };

  ngOnInit() {

    this.registerservice.getpages().pipe(first()).subscribe(
      data => {
        this.user_list = data;
      },

      error => {

      });


      this.route.queryParams.subscribe(params => {
        if(params.updatepage !== undefined && params.updatepage === 'true') {
            this.infoMessage = 'Updated successfully';
        }
      });
  }

  onCustom(event) {

    switch ( event.action) {
      case 'editpage':
        this.router.navigate(['pages/update-page/' + event.data.id]);
        break;

    }
  }



}
