import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { PwaadminService } from '../pwaadmin.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-update-activity',
  templateUrl: './update-activity.component.html',
  styleUrls: ['./update-activity.component.css']
})
export class UpdateActivityComponent implements OnInit {

  public angForm: FormGroup;
  public permissionForm: FormGroup;
  submitted = false;
  message1 = '';
  status = '';
  userdata = [];
  categoryid:any;

  constructor(private fb: FormBuilder,private router:Router, private registerservice: PwaadminService,private route: ActivatedRoute) {}


  ngOnInit() {

    this.categoryid = this.route.snapshot.params.id;

    this.registerservice.getactivity(this.categoryid).pipe(first()).subscribe(
      data => {

      this.userdata = data;
      this.angForm.controls.name.setValue(data.name);
      },

      error => {
      });

    this.angForm = this.fb.group({
      name: ['', Validators.required],
      });

  }

  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {

    this.submitted = true;

    this.registerservice.activityupdate( this.categoryid ,this.angForm.value.name)
      .pipe(first())
      .subscribe(
      data => {

      this.submitted = true;

      this.router.navigate(['activity/list-activity'], {queryParams: { updateactivity: 'true'} });
  },

  error => {

    this.router.navigate(['activity/list-activity']);
  });
  }


}
