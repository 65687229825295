import { ActivityComponent } from './activity/activity.component';
import { ImagesProductComponent } from './images-product/images-product.component';
import { ContactusComponent } from './contactus/contactus.component';
import { UpdatePageComponent } from './update-page/update-page.component';
import { UpdateFaqsectionComponent } from './update-faqsection/update-faqsection.component';
import { CreateFaqsectionComponent } from './create-faqsection/create-faqsection.component';
import { FaqsectionComponent } from './faqsection/faqsection.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { UserComponent } from './user/user.component';
import { QuestionComponent } from './question/question.component';
import { CreateAdminUserComponent } from './create-admin-user/create-admin-user.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate, ActivatedRoute } from '@angular/router';
import { AuthguardGuard } from './authguard.guard';
import { AdminUserComponent } from './admin-user/admin-user.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { UpdateAdminUserComponent } from './update-admin-user/update-admin-user.component';
import { DeleteAdminUserComponent } from './delete-admin-user/delete-admin-user.component';
import { PagesComponent } from './pages/pages.component';
import { ProductComponent } from './product/product.component';
import { CreateProductComponent } from './create-product/create-product.component';
import { UpdateProductComponent } from './update-product/update-product.component';
import { DeleteProductComponent } from './delete-product/delete-product.component';
import { CategoryComponent } from './category/category.component';
import { CreateCategoryComponent } from './create-category/create-category.component';
import { UpdateCategoryComponent } from './update-category/update-category.component';
import { DeleteCategoryComponent } from './delete-category/delete-category.component';
import { CreateQuestionComponent } from './create-question/create-question.component';
import { UpdateQuestionComponent } from './update-question/update-question.component';
import { UpdateSettingComponent } from './update-setting/update-setting.component';
import { CreateActivityComponent } from './create-activity/create-activity.component';
import { UpdateActivityComponent } from './update-activity/update-activity.component';

const routes: Routes = [

  { path: '',component: LoginComponent , data : { title: 'Login'} },
  { path: 'login', component: LoginComponent , data : { title: 'Login'}},
  {
    path: 'admin-user', canActivate: [AuthguardGuard] ,
    data : { title: 'Admin user', breadcrumb: 'Admin user'},
    children: [
      {
        path: 'list-user',
        component: AdminUserComponent,
        data: {
          title: "Admin user List",
          breadcrumb: "Admin user List"
        }
      },
      {
        path: 'create-user',
        component: CreateAdminUserComponent,
        data: {
          title: "Create admin user",
          breadcrumb: "Admin user List"
        }
      },
      {
        path: 'update-user/:id',
        component: UpdateAdminUserComponent,
        data: {
          title: "Update admin user",
          breadcrumb: "Admin user List"
        }
      },
      {
        path: 'delete-user/:id',
        component: DeleteAdminUserComponent,
        data: {
          title: "Delete admin user",
          breadcrumb: "Admin user List"
        }
      }
    ]
  },
  {
    path: 'users', canActivate: [AuthguardGuard] ,
    data : { title: 'Users', breadcrumb: 'Users'},
    children: [
      {
        path: 'list-user',
        component: UserComponent,
        data: {
          title: "Users List",
          breadcrumb: "Users List"
        }
      },
      {
        path: 'create-user',
        component: CreateUserComponent,
        data: {
          title: "Create user",
          breadcrumb: "User List"
        }
      },
      {
        path: 'update-user/:id',
        component: UpdateUserComponent,
        data: {
          title: "Update user",
          breadcrumb: "User List"
        }
      }
    ]
  },
  {
    path: 'product', canActivate: [AuthguardGuard] ,
    data : { title: 'Product', breadcrumb: 'Product'},
    children: [
      {
        path: 'list-product',
        component: ProductComponent,
        data: {
          title: "Product List",
          breadcrumb: "Product List"
        }
      },
      {
        path: 'create-product',
        component: CreateProductComponent,
        data: {
          title: "Create product",
          breadcrumb: "Create product"
        }
      },
      {
        path: 'update-product/:id',
        component: UpdateProductComponent,
        data: {
          title: "Update product",
          breadcrumb: "Product List"
        }
      },
      {
        path: 'images-product/:id',
        component: ImagesProductComponent,
        data: {
          title: "Update product images",
          breadcrumb: "Product List"
        }
      },
      {
        path: 'delete-product/:id',
        component: DeleteProductComponent,
        data: {
          title: "Delete product",
          breadcrumb: "Product List"
        }
      }
    ]
  },
  {
    path: 'category', canActivate: [AuthguardGuard] ,
    data : { title: 'Category', breadcrumb: 'Category'},
    children: [
      {
        path: 'list-category',
        component: CategoryComponent,
        data: {
          title: "Category List",
          breadcrumb: "Category List"
        }
      },
      {
        path: 'create-category',
        component: CreateCategoryComponent,
        data: {
          title: "Create category",
          breadcrumb: "Create category"
        }
      },
      {
        path: 'update-category/:id',
        component: UpdateCategoryComponent,
        data: {
          title: "Update category",
          breadcrumb: "Category List"
        }
      },
      {
        path: 'delete-category/:id',
        component: DeleteCategoryComponent,
        data: {
          title: "Delete category",
          breadcrumb: "Category List"
        }
      }
    ]
  },
  {
    path: 'question', canActivate: [AuthguardGuard] ,
    data : { title: 'Question', breadcrumb: 'Question'},
    children: [
      {
        path: 'list-question',
        component: QuestionComponent,
        data: {
          title: "Question List",
          breadcrumb: "Question List"
        }
      },
      {
        path: 'create-question',
        component: CreateQuestionComponent,
        data: {
          title: "Create question",
          breadcrumb: "Create question"
        }
      },
      {
        path: 'update-question/:id',
        component: UpdateQuestionComponent,
        data: {
          title: "Update question",
          breadcrumb: "Question List"
        }
      },
      {
        path: 'delete-question/:id',
        component: DeleteCategoryComponent,
        data: {
          title: "Delete question",
          breadcrumb: "Question List"
        }
      }
    ]
  },
  {
    path: 'activity', canActivate: [AuthguardGuard] ,
    data : { title: 'Activity', breadcrumb: 'activity'},
    children: [
      {
        path: 'list-activity',
        component: ActivityComponent,
        data: {
          title: "activity List",
          breadcrumb: "activity List"
        }
      },
      {
        path: 'create-activity',
        component: CreateActivityComponent,
        data: {
          title: "Create activity",
          breadcrumb: "Create activity"
        }
      },
      {
        path: 'update-activity/:id',
        component: UpdateActivityComponent,
        data: {
          title: "Update activity",
          breadcrumb: "Activity List"
        }
      },
      {
        path: 'delete-activity/:id',
        component: UpdateActivityComponent,
        data: {
          title: "Delete activity",
          breadcrumb: "activity List"
        }
      }
    ]
  },
  {
    path: 'pages', canActivate: [AuthguardGuard] ,
    data : { title: 'Pages', breadcrumb: 'Pages'},
    children: [
      {
        path: 'list-page',
        component: PagesComponent,
        data: {
          title: "Pages List",
          breadcrumb: "Pages List"
        }
      },
      {
        path: 'update-page/:id',
        component: UpdatePageComponent,
        data: {
          title: "Update page",
          breadcrumb: "Pages List"
        }
      },
      {
        path: 'delete-page/:id',
        component: DeleteAdminUserComponent,
        data: {
          title: "Delete page",
          breadcrumb: "Page List"
        }
      }
    ]
  },
  {
    path: 'faqsection', canActivate: [AuthguardGuard] ,
    data : { title: 'FAQ section', breadcrumb: 'FAQ section'},
    children: [
      {
        path: 'list-faqsection',
        component: FaqsectionComponent,
        data: {
          title: "FAQ List",
          breadcrumb: "FAQ List"
        }
      },
      {
        path: 'create-faqsection',
        component: CreateFaqsectionComponent,
        data: {
          title: "Create FAQ",
          breadcrumb: "FAQ List"
        }
      },
      {
        path: 'update-faqsection/:id',
        component: UpdateFaqsectionComponent,
        data: {
          title: "Update FAQ",
          breadcrumb: "FAQ List"
        }
      },

    ]
  },
  {
    path: 'contact', canActivate: [AuthguardGuard] ,
    data : { title: 'Contact', breadcrumb: 'Contact'},
    children: [
      {
        path: 'list-contact',
        component: ContactusComponent,
        data: {
          title: "Contact List",
          breadcrumb: "Contact List"
        }
      },

    ]
  },
  {
    path: 'setting', canActivate: [AuthguardGuard] ,
    data : { title: 'Settings', breadcrumb: 'Settings'},
    children: [
      {
        path: 'update-setting',
        component: UpdateSettingComponent,
        data: {
          title: "Update settings",
          breadcrumb: "Settings"
        }
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
