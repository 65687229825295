import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-admin-user',
  templateUrl: './delete-admin-user.component.html',
  styleUrls: ['./delete-admin-user.component.css']
})
export class DeleteAdminUserComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
