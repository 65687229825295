import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { PwaadminService } from '../pwaadmin.service';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-update-question',
  templateUrl: './update-question.component.html',
  styleUrls: ['./update-question.component.css']
})
export class UpdateQuestionComponent implements OnInit {

  public angForm: FormGroup;
  public permissionForm: FormGroup;
  submitted = false;
  message1 = '';
  status = '';
  userdata = [];
  productid:any;
  public category_list = [];

  constructor(private fb: FormBuilder,private router:Router, private registerservice: PwaadminService,private route: ActivatedRoute) {}


  ngOnInit() {


    this.registerservice.getcategorylist().pipe(first()).subscribe(
      data => {
        this.category_list = data;
      },

      error => {

      });

    this.productid = this.route.snapshot.params.id;

    this.registerservice.getquestionsingle(this.productid).pipe(first()).subscribe(
      data => {

      this.userdata = data;
      this.angForm.controls.question.setValue(data.question);
      this.angForm.controls.answername1.setValue(data.answer1);
      this.angForm.controls.answername2.setValue(data.answer2);
      this.angForm.controls.answername3.setValue(data.answer3);
      this.angForm.controls.categoryname.setValue(data.categoryid);
      this.angForm.controls.answerradio.setValue(data.rightanswer);
      },

      error => {
      });

    this.angForm = this.fb.group({
      question: ['', Validators.required],
      answerradio: ['', Validators.required],
      answername1: ['', Validators.required],
      answername2: ['', [Validators.required]],
      answername3: ['', [Validators.required]],
      categoryname: ['', [Validators.required]],
      });

  }

  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {

    this.submitted = true;

    this.registerservice.questionlistupdate( this.productid ,this.angForm.value.categoryname,this.angForm.value.question,this.angForm.value.answerradio,
      this.angForm.value.answername1,this.angForm.value.answername2,
      this.angForm.value.answername3)
      .pipe(first())
      .subscribe(
      data => {
          this.submitted = true;
          this.router.navigate(['question/list-question'], {queryParams: { updatequestion: 'true'} });
      },

  error => {
    console.log(error);
    this.router.navigate(['question/list-question']);
  });
  }

}
