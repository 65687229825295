import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { digitalListDB } from 'src/app/shared/tables/digital-list';
import { PwaadminService } from '../pwaadmin.service';
@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {
  public user_list = [];
  infoMessage:any;
  message1 = '';

  constructor( private registerservice: PwaadminService,private router:Router,private route: ActivatedRoute
    ) {
    //this.user_list = userListDB.list_user;

  }

  public settings = {
    mode: 'inline',
    delete: {confirmDelete: true},
    pager : {
      perPage :10
    },
	  actions: {
      position: 'right',
      add: false,
      edit: false,
      custom: [
        {
          name: 'editquestion',
          title: '<i class="fa fa-check"></i>',
        }

      ],
    },
    columns: {
      question: {
        title: 'Question Name',
      },
      categoryname: {
        title: 'Category Name',
      },


    },

  };

  ngOnInit() {

    this.registerservice.getquestionlist().pipe(first()).subscribe(
      data => {
        this.user_list = data;
      },

      error => {

      });

      this.route.queryParams.subscribe(params => {
        if(params.addquestion !== undefined && params.addquestion === 'true') {
            this.infoMessage = 'Added successfully';
        }
      });

      this.route.queryParams.subscribe(params => {
        if(params.updatequestion !== undefined && params.updatequestion === 'true') {
            this.infoMessage = 'Updated successfully';
        }
      });
  }

  onCustom(event) {

    switch ( event.action) {
      case 'editquestion':
        this.router.navigate(['question/update-question/' + event.data.questionid]);
        break;

    }
  }

  onDeleteConfirm(event) {

    if (window.confirm('Are you sure you want to delete?')) {
      event.confirm.resolve();

      this.registerservice.questiondelete(event.data.questionid).pipe(first()).subscribe(
      data => {
      this.message1 = data.message;
      },

      error => {
      });

    } else {
      event.confirm.reject();
    }
  }

}
