import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { PwaadminService } from '../pwaadmin.service';
import { Router,ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  public user_list = [];
  infoMessage:any;
  message1 = '';

  constructor( private registerservice: PwaadminService,private router:Router,private route: ActivatedRoute
    ) { }


  public settings = {
    mode: 'inline',
    delete: {confirmDelete: true},
    pager : {
      perPage :10
    },
	  actions: {
      position: 'right',
      add: false,
      edit: false,
      custom: [
        {
          name: 'edituser',
          title: '<i class="fa fa-check"></i>',
        }

      ],
    },
    columns: {

      fname: {
        title: 'First Name',
      },
      lname: {
        title: 'Last Name'
      },
      email: {
        title: 'Email'
      },
      code: {
        title: 'Code'
      },
    },

  };

  ngOnInit() {

    this.registerservice.getusers().pipe(first()).subscribe(
      data => {
        this.user_list = data;
      },

      error => {

      });

      this.route.queryParams.subscribe(params => {
        if(params.updateuser !== undefined && params.adduser === 'true') {
            this.infoMessage = 'Added successfully';
        }
      });

      this.route.queryParams.subscribe(params => {
        if(params.updateuser !== undefined && params.updateuser === 'true') {
            this.infoMessage = 'Updated successfully';
        }
      });
  }

  onCustom(event) {

    switch ( event.action) {
      case 'edituser':
        this.router.navigate(['users/update-user/' + event.data.id]);
        break;

    }
  }

  onDeleteConfirm(event) {

    if (window.confirm('Are you sure you want to delete?')) {
      event.confirm.resolve();

      this.registerservice.userdelete(event.data.id).pipe(first()).subscribe(
      data => {
      this.message1 = data.message;
      },

      error => {
      });

    } else {
      event.confirm.reject();
      console.log('reject');
    }
  }

}
