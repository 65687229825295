import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { PwaadminService } from '../pwaadmin.service';
import * as glob from '../globals';
import { Router,ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-update-setting',
  templateUrl: './update-setting.component.html',
  styleUrls: ['./update-setting.component.css']
})
export class UpdateSettingComponent {
  baseUrl:string =  glob.baseurl;
  public user_list = [];
  submitted = false;
  message1 = '';
  status = '';
  userdata = [];
  logolink = '';
  public myForm: FormGroup;

  imageSrc: string;

 constructor(private router:Router,private route: ActivatedRoute,private fb: FormBuilder,private http: HttpClient,private registerservice: PwaadminService) { }

 ngOnInit() {

  this.myForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    contactno: ['', Validators.required],
    address: ['', Validators.required],
  /*  file: ['', ''],
    fileSource: ['', ''], */
    });

    this.route.queryParams.subscribe(params => {
      if(params.updatesetting !== undefined && params.updatesetting === 'true') {
          this.message1 = 'Updated successfully';
      }
    });

  this.registerservice.singlesetting().pipe(first()).subscribe(
   data => {
 console.log(this.userdata);
   this.userdata = data;
   this.logolink = this.baseUrl + '/' + data.logo;
   this.myForm.controls.email.setValue(data.email);
   this.myForm.controls.address.setValue(data.address);
   this.myForm.controls.contactno.setValue(data.contactno);
   },

   error => {
   });

}


 get f(){
   return this.myForm.controls;
 }

 onFileChange(event) {
  console.log(this.baseUrl);
  const reader = new FileReader();

  if(event.target.files && event.target.files.length) {
    const [file] = event.target.files;
    reader.readAsDataURL(file);

    reader.onload = () => {

      this.imageSrc = reader.result as string;

      this.myForm.patchValue({
        fileSource: reader.result
      });

    };

  }
}

 submit(){
  this.submitted = true;
   this.http.post(this.baseUrl + '/updateasetting.php', this.myForm.value)
     .subscribe(res => {
      this.router.navigate(['setting/update-setting'], {queryParams: { updatesetting : 'true'} });

     })
 }

}
