<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
      <div class="col-sm-12">
          <div class="card tab2-card">

              <div class="card-body tab2-card">

     <div class="tab-pane fade active show" id="account" role="tabpanel" aria-labelledby="account-tab">
      <div  *ngIf=submitted>
        <div *ngIf="status;else other_content">
          <div class="alert alert-success"> {{ message1 }}</div>
        </div>

        <ng-template #other_content> <span class="alert alert-danger"> {{ message1 }}</span> </ng-template>


      </div>

      <form [formGroup]="angForm" (ngSubmit)="onsubmit()" autocomplete="off">


        <div class="form-group row">
          <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
               Category </label>

               <select formControlName="categoryname" class="form-control col-xl-8 col-md-7">
                <option value=""> Select category</option>
                 <option *ngFor="let category of category_list;"   value="{{ category.categoryid }}"> {{ category.name }}</option>
              </select>

              <span class="col-xl-3 col-md-4"></span>
          <span class="text-danger col-xl-8 col-md-7"
              *ngIf="(registerFormControl.categoryname.touched || submitted) && registerFormControl.categoryname.errors?.required" >
              Category is required
          </span>
      </div>

        <div class="form-group row">
          <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
               Question </label>

              <input type="text" class="form-control col-xl-8 col-md-7" formControlName="question"  />

              <span class="col-xl-3 col-md-4"></span>
          <span class="text-danger col-xl-8 col-md-7"
              *ngIf="(registerFormControl.question.touched || submitted) && registerFormControl.question.errors?.required" >
              Question is required
          </span>
      </div>

          <div class="form-group row">
            <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
                Answer 1</label>
                <input class="answerradio col-xl-1 col-md-1" type="radio" name="answerradio"  value="answer1"  id="answerradio1" formControlName="answerradio" />
                <input type="text" class="form-control col-xl-7 col-md-6" formControlName="answername1"  />

                <span class="col-xl-3 col-md-4"></span>
            <span class="text-danger col-xl-8 col-md-7"
                *ngIf="(registerFormControl.answername1.touched || submitted) && registerFormControl.answername1.errors?.required" >
                Answer 1 is required
            </span>
        </div>

        <div class="form-group row">
          <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
              Answer 2</label>
              <input class="answerradio col-xl-1 col-md-1" type="radio"  name="answerradio" value="answer2" id="answerradio2" formControlName="answerradio"/>
              <input type="text"class="form-control col-xl-7 col-md-6" formControlName="answername2"  />

              <span class="col-xl-3 col-md-4"></span>
          <span class="text-danger col-xl-8 col-md-7"
              *ngIf="(registerFormControl.answername2.touched || submitted) && registerFormControl.answername2.errors?.required" >
              Answer 2 is required
          </span>
      </div>

      <div class="form-group row">
        <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
            Answer 3</label>
            <input class="answerradio col-xl-1 col-md-1" id="answerradio3" type="radio" value="answer3" name="answerradio" formControlName="answerradio">
            <input type="text" class="form-control col-xl-7 col-md-6" formControlName="answername3"  />

            <span class="col-xl-3 col-md-4"></span>
        <span class="text-danger col-xl-8 col-md-7"
            *ngIf="(registerFormControl.answername3.touched || submitted) && registerFormControl.answername3.errors?.required" >
            Answer 3 is required
        </span>
    </div>



    <div class="form-group row">
        <span class="col-xl-3 col-md-4"></span>
        <span class="text-danger col-xl-8 col-md-7"
        *ngIf="(registerFormControl.answerradio.touched || submitted) && registerFormControl.answerradio.errors?.required" >
        Answer radio options is required
      </span>
    </div>
       <div class="pull-right">
         <button type="submit" [disabled]="angForm.invalid" class="btn btn-primary" >Create</button>
     </div>
   </form>
</div>

              </div>
          </div>
      </div>
  </div>
</div>
<!-- Container-fluid Ends-->
