import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { PwaadminService } from '../pwaadmin.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm!: FormGroup;
  submitted = false;
  message1 = '';
  status = '';
  invalidlogin: boolean = false;
constructor(private fb: FormBuilder,private router:Router, private registerservice: PwaadminService) {}

ngOnInit() {

  this.loginForm = this.fb.group({
    email: ['', [Validators.required,Validators.minLength(1), Validators.email]],
    password: ['', Validators.required]
    });
}
get loginFormControl() {
  return this.loginForm.controls;
}

onsubmit() {

  this.submitted = true;

  this.registerservice.userlogin(this.loginForm.value.email,this.loginForm.value.password)
.pipe(first())
.subscribe(
data => {
  this.message1 = data.message;
  this.status = data.status;
  if(data.status) {

      window.localStorage.setItem('users' , data.userid)
      this.router.navigate(['admin-user/list-user']);

  }
},

error => {
  console.log(error);

});
}

}
