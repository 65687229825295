import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { PwaadminService } from '../pwaadmin.service';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-update-category',
  templateUrl: './update-category.component.html',
  styleUrls: ['./update-category.component.css']
})
export class UpdateCategoryComponent implements OnInit {

  public angForm: FormGroup;
  public permissionForm: FormGroup;
  submitted = false;
  message1 = '';
  status = '';
  userdata = [];
  categoryid:any;

  constructor(private fb: FormBuilder,private router:Router, private registerservice: PwaadminService,private route: ActivatedRoute) {}


  ngOnInit() {

    this.categoryid = this.route.snapshot.params.id;

    this.registerservice.getcategory(this.categoryid).pipe(first()).subscribe(
      data => {

      this.userdata = data;
      this.angForm.controls.name.setValue(data.categoryname);
      this.angForm.controls.color.setValue(data.color);
      },

      error => {
      });

    this.angForm = this.fb.group({
      name: ['', Validators.required],
      color: ['', Validators.required],
      });

  }

  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {

    this.submitted = true;

    this.registerservice.categorylistupdate( this.categoryid ,this.angForm.value.name,this.angForm.value.color)
      .pipe(first())
      .subscribe(
      data => {

      this.submitted = true;

      this.router.navigate(['category/list-category'], {queryParams: { updatecategory: 'true'} });
  },

  error => {
    console.log(error);
    this.router.navigate(['category/list-category']);
  });
  }

}
