import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { digitalListDB } from 'src/app/shared/tables/digital-list';
import { PwaadminService } from '../pwaadmin.service';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  public user_list = [];
  infoMessage:any;
  message1 = '';

  constructor( private registerservice: PwaadminService,private router:Router,private route: ActivatedRoute
    ) {
    //this.user_list = userListDB.list_user;

  }

  public settings = {
    mode: 'inline',
    delete: {confirmDelete: true},
    pager : {
      perPage :10
    },
	  actions: {
      position: 'right',
      add: false,
      edit: false,
      custom: [
        {
          name: 'editproduct',
          title: '<i class="fa fa-check"></i>',
        },
        {
          name: 'imagesproduct',
          title: '<i class="fa fa-file-image-o"></i>',
        }

      ],
    },
    columns: {
      productname: {
        title: 'Product Name',
      },
      productprice: {
        title: 'Product Price'
      },

    },

  };

  ngOnInit() {

    this.registerservice.getproductlist().pipe(first()).subscribe(
      data => {
        this.user_list = data;
      },

      error => {

      });

      this.route.queryParams.subscribe(params => {
        if(params.addproduct !== undefined && params.addproduct === 'true') {
            this.infoMessage = 'Added successfully';
        }
      });

      this.route.queryParams.subscribe(params => {
        if(params.updateproduct !== undefined && params.updateproduct === 'true') {
            this.infoMessage = 'Updated successfully';
        }
      });
  }

  onCustom(event) {

    switch ( event.action) {
      case 'editproduct':
        this.router.navigate(['product/update-product/' + event.data.productid]);
        break;
      case 'imagesproduct':
        this.router.navigate(['product/images-product/' + event.data.productid]);
        break;

    }
  }

  onDeleteConfirm(event) {

    if (window.confirm('Are you sure you want to delete?')) {
      event.confirm.resolve();

      this.registerservice.productdelete(event.data.productid).pipe(first()).subscribe(
      data => {
      this.message1 = data.message;
      },

      error => {
      });

    } else {
      event.confirm.reject();
    }
  }

}
