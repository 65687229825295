import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor() {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize() {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
    {
			title: 'Users', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/users/list-user', title: 'User List', type: 'link' },
				{ path: '/users/create-user', title: 'Create User', type: 'link' },
			]
		},
		{
			title: 'Products', icon: 'box', type: 'sub', active: false, children: [

						{ path: '/product/list-product', title: 'Product List', type: 'link' },
						{ path: '/product/create-product', title: 'Add Product', type: 'link' },

			]
		},
    {
			title: 'Category', icon: 'box', type: 'sub', active: false, children: [
						{ path: '/category/list-category', title: 'Category List', type: 'link' },
					/*	{ path: '/category/create-category', title: 'Add Category', type: 'link' }, */
			]
		},
    {
			title: 'Question', icon: 'help-circle', type: 'sub', active: false, children: [
						{ path: '/question/list-question', title: 'Question List', type: 'link' },
						{ path: '/question/create-question', title: 'Add Question', type: 'link' },
			]
		},
    {
			title: 'Activities', icon: 'help-circle', type: 'sub', active: false, children: [
						{ path: '/activity/list-activity', title: 'Activity List', type: 'link' },
						{ path: '/activity/create-activity', title: 'Add Activity', type: 'link' },
			]
		},
	/*	{
			title: 'Sales', icon: 'dollar-sign', type: 'link', path: '/sales/orders'
		}, */

		{
			title: 'Admin Users', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/admin-user/list-user', title: 'User List', type: 'link' },
				{ path: '/admin-user/create-user', title: 'Create User', type: 'link' },
			]
		},
    {
			title: 'FAQ section', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/faqsection/list-faqsection', title: 'FAQ List', type: 'link' },
				{ path: '/faqsection/create-faqsection', title: 'Create FAQ', type: 'link' },
			]
		},
    {
			title: 'Pages', icon: 'clipboard', type: 'sub', active: false, children: [
				{ path: '/pages/list-page', title: 'List Page', type: 'link' }
			]
		},
    {
			title: 'Contact Details', icon: 'dollar-sign', type: 'link', path: '/contact/list-contact'
		},
    {
			title: 'Settings', icon: 'dollar-sign', type: 'link', path: '/setting/update-setting'
		},
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
