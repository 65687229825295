import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { PwaadminService } from '../pwaadmin.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.css']
})
export class CreateProductComponent implements OnInit {

  public angForm: FormGroup;
  public permissionForm: FormGroup;
  submitted = false;
  message1 = '';
  status = '';

  constructor(private fb: FormBuilder,private router:Router, private registerservice: PwaadminService) {}


  ngOnInit() {
    this.angForm = this.fb.group({
      productname: ['', Validators.required],
      productprice: ['', Validators.required],
      productdetail: ['', Validators.required],
      productdescription: ['', [Validators.required]],
      });
  }

  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {

    this.submitted = true;

    this.registerservice.productlistcreate(this.angForm.value.productname,this.angForm.value.productprice,this.angForm.value.productdetail,this.angForm.value.productdescription)
      .pipe(first())
      .subscribe(
      data => {
        this.submitted = true;

        this.router.navigate(['product/list-product'], {queryParams: { addproduct: 'true'} });
      },

      error => {
      });
  }


}
