<div class="container-fluid">
  <div class="row">
      <div class="col-md-6 footer-copyright">
          <p class="mb-0">Copyright 2021 © Excellence Black Edition All rights reserved.</p>
      </div>
      <div class="col-md-6">

      </div>
  </div>
</div>
