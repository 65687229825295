<div class="page-main-header" [class.open]="open">
  <div class="main-header-right row">
      <div class="main-header-left d-lg-none">
          <div class="logo-wrapper"><a [routerLink]="'/dashboard/default'"><img class="blur-up lazyloaded"
                      src="assets/images/dashboard/admin_logo2.png" alt=""></a></div>
      </div>
      <div class="mobile-sidebar">
          <div class="media-body text-right switch-sm">
              <label class="switch">
                  <a>
                      <app-feather-icons id="sidebar-toggle" [icon]="'align-left'" (click)="collapseSidebar()">
                      </app-feather-icons>
                  </a>
              </label>
          </div>
      </div>
      <div class="nav-right col">
          <ul class="nav-menus" [ngClass]="{'open' : openNav}">



              <li></li>
              <li class="onhover-dropdown">
                  <div class="media align-items-center"><button class="btn btn-primary">Admin User</button>

                      <div class="dotted-animation"><span class="animate-circle"></span><span
                              class="main-circle"></span></div>
                  </div>
                  <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">

                      <li><a [routerLink]="" (click)="logout()">
                              <app-feather-icons [icon]="'log-out'"></app-feather-icons>Logout
                          </a></li>
                  </ul>
              </li>
          </ul>
          <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
              <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
          </div>
      </div>
  </div>
</div>
